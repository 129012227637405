<template>
  <div class="jsapi-pay-page">
    <div class="success-icon">
      <img src="../assets/images/pay_success.png" alt="" />
    </div>
    <h3>支付成功</h3>
    <div class="pay-money">
      <span class="unit">￥</span
      >{{ mathManage.formatMoney(orderInfo.orderRealMoney, 2) }}
    </div>
    <div class="tip">
      <span
        v-if="
          orderInfo.couponPrice &&
          (orderInfo.payType == 20 || orderInfo.payType == 40)
        "
        >卡券已优惠 ￥{{
          mathManage.formatMoney(orderInfo.couponPrice, 2)
        }}</span
      >
    </div>
    <span class="to-btn" v-if="orderInfo" @click="toDetails">查看详情</span>
  </div>
</template>

<script>
const gardenPlan = require("@/utils/jgoldplan");
import mathManage from "@/utils/mathManage";
import { getGoldOrderInfo } from "@/api/common";
export default {
  data() {
    return {
      mathManage,
      sub_mch_id: "",
      out_trade_no: "",
      check_code: "",
      orderInfo: {},
    };
  },
  mounted() {
    this.sub_mch_id = this.$route.query.sub_mch_id; //特约商户号
    this.out_trade_no = this.$route.query.out_trade_no; //商户订单号
    this.check_code = this.$route.query.check_code;
    let mchData = {
      action: "onIframeReady",
      displayStyle: "SHOW_CUSTOM_PAGE",
    };
    let postData = JSON.stringify(mchData);
    parent.postMessage(postData, "https://payapp.weixin.qq.com");
    this.$nextTick(() => {
      this.getOrderDetail();
    });
  },
  methods: {
    // 获取订单编号
    async getOrderDetail() {
      const res = await getGoldOrderInfo({
        subMchId: this.sub_mch_id,
        outTradeNo: this.out_trade_no,
        checkCode: this.check_code,
      });
      if (res && res.code == 0) {
        this.orderInfo = res.data;
      } else {
        this.$toast.error(res.message);
      }
    },
    toDetails() {
      const mchData = {
        action: "jumpOut",
        jumpOutUrl: `${this.orderInfo.subMerchantDomain}/mall/orderDetails/${this.orderInfo.orderId}`, //跳转的页面
      };
      const pData = JSON.stringify(mchData);
      parent.postMessage(pData, "https://payapp.weixin.qq.com");
    },
  },
};
</script>

<style lang="less" scoped>
.jsapi-pay-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin: 0 auto;

  .success-icon {
    margin-top: 0.32rem;
    width: 0.96rem;
    height: 0.96rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    margin-top: 0.2rem;
    font-size: 0.4rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #303133;
    text-align: center;
  }

  .pay-money {
    margin-top: 0.32rem;
    font-size: 0.72rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #303133;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .unit {
      font-size: 0.4rem;
      margin-right: 0.04rem;
    }
  }

  .tip {
    height: 0.4rem;
    margin-top: 0.2rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #909399;
  }

  .to-btn {
    margin-top: 2rem;
    width: 2.4rem;
    height: 0.72rem;
    border-radius: 0.39rem;
    border: 0.02rem solid #e8e8e8;
    font-family: Pingfang SC;
    font-size: 0.32rem;
    font-weight: 400;
    color: #303133;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>