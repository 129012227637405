(function() {
  var d = "";
  window.addEventListener("message", function(a) {
    var b = "string" == typeof a.data ? JSON.parse(a.data) : a.data;
    console.log("parent origin", a.origin);
    console.log("parent data", b);
    "https://payapp.weixin.qq.com" === a.origin &&
    Object.prototype.hasOwnProperty.call(b, "action") &&
    "setToken" === b.action &&
    Object.prototype.hasOwnProperty.call(b, "token")
      ? (d = b.token)
      : console.log("valid");
  });
  window.addEventListener("pagehide", function() {
    var a = JSON.stringify({
      action: "onSubPageLeave",
      subPageUrl: window.location.href,
      token: d,
      sdkVersion: "0.1",
    });
    console.log("child page will leave");
    parent.postMessage(a, "https://payapp.weixin.qq.com");
  });
  var c = {
    action: "onLoadSdk",
    subPageUrl: window.location.href,
    token: d,
    sdkVersion: "0.1",
  };
  console.log("send message", c);
  c = JSON.stringify(c);
  parent.postMessage(c, "https://payapp.weixin.qq.com");
})(); /*  |xGv00|08097d7ae5858d3c6941d62e581cff57 */
